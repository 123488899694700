const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/,
    ),
);

export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      let swUrl = `${window.location.origin}/service-worker.js`

      if (isLocalhost) {
        checkValidServiceWorker(swUrl);
        navigator.serviceWorker.ready.then(() => {
          // eslint-disable-next-line no-console
          console.log("Service worker ready.");
        });
      } else {
        registerValidSW(swUrl);
      }
    });
  }
}

function checkValidServiceWorker(swUrl: string) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found, reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log(
        "No internet connection found. App is running in offline mode.",
      );
    });
}

function registerValidSW(swUrl: string) {
  navigator.serviceWorker.register(swUrl).catch((error) => {
    console.error("Error during service worker registration:", error);
  });
}
